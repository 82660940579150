import * as React from 'react';
import { connect, FormikProps } from 'formik';
import { FormattedMessage } from 'react-intl';
import { MDCCheckbox } from '@material/checkbox';

class ConsentForm extends React.Component<{ formik: FormikProps<any> }> {
  private newsletter: React.RefObject<any>

  private newsletterCheckbox: MDCCheckbox

  constructor(props) {
    super(props)

    this.newsletter = React.createRef()
  }

  componentDidMount() {
    this.newsletterCheckbox = new MDCCheckbox(this.newsletter.current)
    this.newsletterCheckbox.useNativeValidation = true
  }

  render() {
    return (
      <>
        {this.props.formik.values && (
          <div className="mdc-layout-grid__inner">
            <div className="mdc-layout-grid__cell--span-12">
              <h1 className="mdc-typography--headline2">
                <FormattedMessage id="signup.consent.title" defaultMessage="Möchten Sie auf dem aktuellen Stand bleiben?" />
              </h1>
            </div>

            {/* Newsletter */}
            <div className="mdc-layout-grid__cell--span-12">
              <div className="mdc-form-field">
                <div className="mdc-checkbox cm-newsletter" id="newsletter-checkbox" ref={this.newsletter}>
                  <input
                    type="checkbox"
                    className="mdc-checkbox__native-control"
                    id="newsletter"
                    name="user.newsletter"
                    value={this.props.formik.values.user.newsletter}
                    checked={this.props.formik.values.user.newsletter}
                    onChange={this.props.formik.handleChange}
                  />
                  <div className="mdc-checkbox__background">
                    <svg className="mdc-checkbox__checkmark" viewBox="0 0 24 24">
                      <path className="mdc-checkbox__checkmark-path" fill="none" d="M1.73,12.91 8.1,19.28 22.79,4.59" />
                    </svg>
                    <div className="mdc-checkbox__mixedmark" />
                  </div>
                </div>
                <label htmlFor="newsletter">
                  <FormattedMessage
                    id="signup.newsletter"
                    defaultMessage="Ich möchte auf dem neuesten Stand bleiben und Informationen zum Thema Ackerbau erhalten."
                  />
                </label>
              </div>
            </div>
          </div>
        )}
      </>
    )
  }
}

export default connect((ConsentForm as React.ComponentType<any>))
